<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-popup classContent="popup-example" title="Ajouter une Société" :active.sync="popupSociete">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Nom Société" v-validate="'required'" name="NomSociete" v-model="NomSociete" />
            <span class="text-danger text-sm"  v-show="errors.has('NomSociete')">{{ errors.first('NomSociete') }}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Régistre de commerce" name="RegistreCom" v-model="RegistreCom" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="creatSociete" :disabled="!validateForm">Enregistrer</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="reset_data" status = "false" popupSocieteUpdate = "false" >Annuler</vs-button>
          </div>
        </div>
        <!-- </vx-card> -->
      </vs-popup>
      <vs-popup classContent="popup-example" title="Modifier la Société" :active.sync="popupSocieteUpdate">

        <div class="vx-row mb-6">
         <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Nom Société" v-validate="'required'" name="NomSociete" v-model="NomSociete" />
            <span class="text-danger text-sm"  v-show="errors.has('NomSociete')">{{ errors.first('NomSociete') }}</span>
         </div>
        </div>

        <div class="vx-row mb-6">
         <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Régistre de commerce" name="RegistreCom" v-model="RegistreCom" />
         </div>
        </div>


        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="updateSociete" :disabled="!validateForm">Modifier</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="reset_data" popupSocieteUpdate = "false" >Annuler</vs-button>
          </div>
        </div>

      </vs-popup>
      <div class="vx-card p-6">
        <vs-table  noDataText="" pagination max-items="50" stripe search :data="Societes">
          <template slot="header">
            <div class="flex flex-wrap items-center">

              <!-- ITEMS PER PAGE -->
              <div class="flex-grow">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>

                <vs-button
                    class="btn-primary ml-4 mb-2 sm:mb-0"
                    color="primary"
                    @click="popupSociete=true">
                    Ajouter une société
                  </vs-button>
              </div>

            </div>

          </template>
          <template slot="thead">
            <vs-th sort-key="NomSociete">
              Société
            </vs-th>
            <vs-th>
              Régistre de Commerce
            </vs-th>
            <vs-th>
              Actions
            </vs-th>

          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td>
                {{tr.NomSociete}}
              </vs-td>
              <vs-td>
                {{tr.RegistreCom ? tr.RegistreCom : ''}}
              </vs-td>
              <vs-td>
                <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                  <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateSocieteShowForm(tr)" />
                  <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

  </div>

</template>

<script>
import vSelect from 'vue-select'

// Store Module


export default {
  components: {
    vSelect
  },
  data () {
    return {
      NomSociete: null,
      RegistreCom: null,
      popupSociete : false,
      popupSocieteUpdate: false,

      idSociete: null
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.NomSociete !== ''
    },

    Societes () {
      return this.$store.state.societe.societes
    }
  },
  methods: {
    creatSociete () {
      const payload = {
        NomSociete: this.NomSociete,
        RegistreCom: this.RegistreCom
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('societe/addSociete', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          //this.popupUser = false
          this.reset_data()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupSociete = false
        })
    },
    reset_data () {
      this.NomSociete = null
      this.RegistreCom = null
    },
    updateSocieteShowForm (data) {
      this.idSociete = data._id
      this.NomSociete = data.NomSociete
      this.RegistreCom = data.RegistreCom

      this.popupSocieteUpdate = true
    },
    updateSociete () {
      const payload = {
        NomSociete : this.NomSociete,
        RegistreCom : this.RegistreCom,
        id: this.idSociete
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('societe/updateSociete', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
          this.popupSocieteUpdate = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.popupSocieteUpdate = false
        })
    },
    confirmDeleteRecord (data) {
      this.idSociete = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: `Vous allez supprimé la société "${data.NomSociete}"`,
        accept: this.deleteRecord,
        acceptText: 'Supprimer'
      })
    },
    deleteRecord () {
      this.$store.dispatch('societe/removeSociete', this.idSociete)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Société Supprimé',
        text: 'La société selectionné a bien été supprimé'
      })
    },
    getSocietes () {
      this.$store.dispatch('societe/getSocietes')
        .then(() => {
        })
        .catch((err) => { console.log(err) })
    }
  },
  created () {
    this.getSocietes()
  }

}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
